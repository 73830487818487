import MultiStepForm from './components/MultiStepForm';

function App() {
  return (
    <div>
      <MultiStepForm/>
    </div>
  );
}

export default App;
